<div class="bg-image row valign-wrapper">
    <div class="container">
        <div class="card col s12 m12 " style="margin-top: 8%;">
            <div class="card-content">
                <div class="col s12 m6 center-align">
                    <div>
                        <i class="fas fa-envelope contact-icons"></i>
                    </div>
                    <!-- <h5>Nous contacter</h5> -->
                    <form class="col s12">
                        <div class="row">
                            <div class="card-panel red darken-2 lighten-2">Le formulaire de contact est en cours de développement merci de privilégier un contact par téléphone : 06.60.90.98.71 ou mail : stc.industrie@gmail.com</div>
                            <div class="input-field col s12 m6">
                                <input id="nom" type="text" class="validate">
                                <label for="nom">Nom</label>
                            </div>
                            <div class="input-field col s12 m6">
                                <input id="mail" type="text" class="validate">
                                <label for="mail">Email</label>
                            </div>
                        </div>
                        <div class="row">
                        <div class="input-field col s12">
                            <input id="objet" type="text" class="validate">
                            <label for="objet">Objet</label>
                        </div>
                        <div class="col s12" style="text-align: left;">
                            <span style="color: #9e9e9e;">Message</span>
                        </div>
                        <div class="input-field col s12">
                            
                            <textarea id="corps" type="text" class="custom-textarea" style="height: 15vh;"></textarea>
                        </div>
                        <button class="btn waves-effect waves-light" type="submit" name="action" disabled>Soon
                            <i class="material-icons right">send</i>
                          </button>
                        </div>
                    </form>
                </div>
                <div class="col s12 m6 center-align">
                    <div>
                        <i class="fas fa-map-marked-alt contact-icons"></i>
                    </div>
                    <!-- <h5>Nous trouver</h5> -->
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.950610042568!2d4.9856022154794175!3d43.58674507912365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b6033750fe80e9%3A0xb2f320f08560859f!2sSTC%20Industrie!5e0!3m2!1sfr!2sfr!4v1606907482868!5m2!1sfr!2sfr" 
                        width="470" height="420" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    <div>
                        <div class="card-panel">
                            <h6 style="font-style: italic;">
                                <i class="tiny material-icons">phone_iphone</i> Tel : 06.60.90.98.71
                              </h6>
                              <h6 style="font-style: italic;">
                                <i class="tiny material-icons">access_time</i> Horaire : Lundi au Vendredi de 7h30-12h00 à 13h00-17h30
                              </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>