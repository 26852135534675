<div class="bg-image">
    <div class="valign-wrapper logo-centre">
        <img src="../assets/img/LogoSTC.png" width="550px" class="animate__animated animate__zoomIn responsive-img"/>
        <h5 class="center-align hide-on-small-only" style="color: white; width: 100%; font-size: 26pt;">
            Soudure, Tuyauterie, Chaudronnerie industrie
        </h5>
        <h6 class="center-align font-first-page hide-on-small-only" style="color: white; width: 100%; font-style: italic; margin-bottom: 10px;">
            "Avec S.T.C Industrie plus vous saurez regarder loin dans le passé, plus vous verrez loin dans le futur."
        </h6>
    </div>
</div>
