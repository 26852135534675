import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { ContactComponent } from './contact/contact.component';
import { PrestationsComponent } from './prestations/prestations.component';
import { AtelierComponent } from './atelier/atelier.component';
import { GalerieComponent } from './galerie/galerie.component';


const routes: Routes = [
  {
    path: '',
    component: AccueilComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'prestations',
    component: PrestationsComponent
  },
  {
    path: 'atelier',
    component: AtelierComponent
  },
  {
    path: 'galerie',
    component: GalerieComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
