<div class="bg-image row valign-wrapper">
  <div class="container">
    <div class="mobile card col s12 m12 text-center">
      <h3>Nos réalisations  <img src="../../assets/img/soudure.jpg" width="60px" style="float:right;"></h3>
      <hr>
      <div class="card-panel" style="text-align: center;">
        <span class="blue-text text-darken-2">Notre galéries photos/vidéos, est une présentation des projets et travaux réalisées par notre société STC Industrie.<br>
        </span>
      </div>  
         
      <!-- Partie slider -->
      <div class="slider">
        <ul class="slides">
          <li>
            <img src="../../assets/img/stc/galerie4.jpg">
            <div class="caption left-align">
              <h3></h3>
              <h5 class="light grey-text text-lighten-3"></h5>
            </div>
          </li>
          <li>
            <img src="../../assets/img/stc/galerie1.jpg">
            <div class="caption left-align">
              <h3></h3>
              <h5 class="light grey-text text-lighten-3"></h5>
            </div>
          </li>
          <li>
            <img src="../../assets/img/stc/galerie2.jpg">
            <div class="caption left-align">
              <h3 style="color: #2196f3;"></h3>
              <h5 class="light grey-text text-lighten-3"></h5>
            </div>
          </li>
          <li>
            <img src="../../assets/img/stc/galerie 5.jpg">
            <div class="caption right-align">
              <h3>Projet boutique Gaming</h3>
              <h5 class="light grey-text text-lighten-3">Bureau gaming sur mesure</h5>
            </div>
          </li>
          <li>
            <img src="../../assets/img/stc/galerie7.jpg">
            <div class="caption left-align">
              <h3>Projet boutique Gaming</h3>
              <h5 class="light grey-text text-lighten-3">Bureau gaming sur mesure</h5>
            </div>
          </li>
        </ul>
      </div>
        
      <!-- Partie projets video-->
        <div class="card-content">
          <h4 class="titre-video">Nos projets en vidéos</h4>
          <div class="mobile-video row nos-projets-recent">
            <div class="col s8 m6">
              <div class="video-container">
                <iframe width="900" height="680" src="../../assets/img/stc/video11.mp4" frameborder="0" ></iframe>
              </div>
            </div>
            <div class="col s8 m6">
              <div class="video-container">
                <iframe width="900" height="680" src="../../assets/img/stc/video2.mp4"  allowfullscreen></iframe>
              </div>
            </div>
          </div>


          <!-- Partie tous projets --> 
          <!-- ROW 1 -->
          <h4 class="titre-recent">Tous nos projets</h4>
          <div class="row">
            <div class="col s4 m4">
              <div class="card small">
                <div class="card-image waves-effect waves-block waves-light">
                  <img class="activator" src="../../assets/img/stc/galerie1.jpg" class="responsive-img">
                </div>
                <div class="card-content">
                  <span class="card-title activator grey-text text-darken-4">Coude pneumatique HP - 1/2<i class="material-icons right">more_vert</i></span>
                </div>
                <div class="card-reveal">
                  <span class="card-title grey-text text-darken-4">Coude pneumatique HP - 1/2<i class="material-icons right">close</i></span>
                  <p>Description du projet (travail nécessaire, méthodes ...)</p>
                </div>
              </div>
            </div>
            <div class="col s4 m4">
              <div class="card small">
                <div class="card-image waves-effect waves-block waves-light">
                  <img class="activator" src="../../assets/img/stc/galerie2.jpg" class="responsive-img">
                </div>
                <div class="card-content">
                  <span class="card-title activator grey-text text-darken-4">Coude pneumatique HP - 2/2<i class="material-icons right">more_vert</i></span>
                </div>
                <div class="card-reveal">
                  <span class="card-title grey-text text-darken-4">Coude pneumatique HP - 2/2<i class="material-icons right">close</i></span>
                  <p>Description du projet (travail nécessaire, méthodes ...)</p>
                </div>
              </div>
            </div>
            <div class="col s4 m4">
              <div class="card small">
                <div class="card-image waves-effect waves-block waves-light">
                  <img class="activator" src="../../assets/img/stc/galerie3.jpg" class="responsive-img">
                </div>
                <div class="card-content">
                  <span class="card-title activator grey-text text-darken-4">Pied de broyeur<i class="material-icons right">more_vert</i></span>
                </div>
                <div class="card-reveal">
                  <span class="card-title grey-text text-darken-4">Pied de broyeur<i class="material-icons right">close</i></span>
                  <p>Description du projet (travail nécessaire, méthodes ...)</p>
                </div>
              </div>
            </div>
          </div> 
          <!-- ROW 2 -->
          <div class="row">
            <div class="col s4 m4">
              <div class="card small">
                <div class="card-image waves-effect waves-block waves-light">
                  <img class="activator" src="../../assets/img/stc/galerie 5.jpg" class="responsive-img">
                </div>
                <div class="card-content">
                  <span class="card-title activator grey-text text-darken-4">Bureau gaming sur mesure<i class="material-icons right">more_vert</i></span>
                </div>
                <div class="card-reveal">
                  <span class="card-title grey-text text-darken-4">Bureau gaming sur mesure<i class="material-icons right">close</i></span>
                  <p>Description du projet (travail nécessaire, méthodes ...)</p>
                </div>
              </div>
            </div>
            <div class="col s4 m4">
              <div class="card small">
                <div class="card-image waves-effect waves-block waves-light">
                  <img class="activator" src="../../assets/img/stc/galerie7.jpg" class="responsive-img">
                </div>
                <div class="card-content">
                  <span class="card-title activator grey-text text-darken-4">Bureau gaming sur mesure<i class="material-icons right">more_vert</i></span>
                </div>
                <div class="card-reveal">
                  <span class="card-title grey-text text-darken-4">Bureau gaming sur mesure<i class="material-icons right">close</i></span>
                  <p>Description du projet (travail nécessaire, méthodes ...)</p>
                </div>
              </div>
            </div>
            <div class="col s4 m4">
              <div class="card small">
                <div class="card-image waves-effect waves-block waves-light">
                  <img class="activator" src="../../assets/img/stc/avenir.png" class="responsive-img">
                </div>
                <div class="card-content">
                  <span class="card-title activator grey-text text-darken-4">Nouveau projet à venir<i class="material-icons right">more_vert</i></span>
                </div>
                <div class="card-reveal">
                  <span class="card-title grey-text text-darken-4">Nouveau projet à venir<i class="material-icons right">close</i></span>
                  <p>Présentation d'un nouveau projet à venir.</p>
                </div>
              </div>
            </div>
          </div>          
        </div>       
    </div>     
  </div>
</div>

