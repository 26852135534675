<div class="bg-image row valign-wrapper">
    <div class="container">
        <div class="mobile card col s12 text-center">
            <h3>Nos prestations  <img src="../../assets/img/soudure.jpg" width="60px" style="float:right;"></h3>
            <hr>
            <div class="card-content">
                <div class="align-text row">
                    <div class="col s12 m6">
                        <img src="../../assets/img/stc/tuyauterie.JPG" width="500px" class="responsive-img">
                    </div>
                    <div class="card col s12 m6">
                        <h5>CHAUDRONNERIE, TUYAUTERIE</h5>
                        <br>
                        <p> Fabrication de pièces en atelier suivant un plan d’ensemble, sur de la 
                            fine/moyenne et forte épaisseur, Acier, Inox, Aluminium.<br>
                        <br>
                        <p>Réalisations cuve, trémie, goulotte et ensembles à la demande etc...</p>
                        <br>
                    </div>
                </div>
                <div class="align-text row">
                    <div class="col s12 m6">
                        <img src="../../assets/img/stc/Soudure-prest.jpg" width="500px" class="responsive-img">
                    </div>
                    <div class="card col s12 m6">
                        <h5>SOUDURE</h5>
                        <br>
                        <p>Soudure de pièces en atelier, sur tous types d'épaisseur en Acier, Inox, Aluminium et autres alliages spéciaux (Titan et Carbonne).<br><br>
                         Soudure multipass et rechargement pour vos gros ouvrages.</p>
                        <br>
                    </div>
                </div>
                <div class="align-text row">
                    <div class="col s12 m6">
                        <img src="../../assets/img/decoupe.jpg" width="500px" class="responsive-img">
                    </div>
                    <div class="card col s12 m6">
                        <h5>DECOUPE LASER</h5>
                        <br>
                        <p>
                            Le découpage laser est une technique non-négligeable dans le domaine du travail des métaux. Par sa grande précision, le laser pourra découper des matériaux très résistants tout en fournissant 
                            un résultat des plus minutieux ! 
                            <br>Nous proposons cette solution, car elle est adaptable à tous les métaux que nous travaillons ce qui permet de vous offrir un travail de meilleur qualité à moindre coûts.
                        </p>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>