<nav>
  <div class="nav-wrapper">
    <a [routerLink]="['/']" class="brand-logo"><img src="../assets/img/LogoSTC.png" width="100px" class="responsive-img" style="margin-left: 10px;"/></a>
    <a [routerLink]="['/']" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i></a>
    <ul class="right hide-on-med-and-down">
      <li class="hover-nav-item"><a [routerLink]="['/']">Accueil</a></li>
      <li class="hover-nav-item"><a [routerLink]="['atelier']">Atelier</a></li>
      <li class="hover-nav-item"><a [routerLink]="['prestations']">Prestations</a></li>
      <li class="hover-nav-item"><a [routerLink]="['galerie']">Galerie</a></li>
      <li class="hover-nav-item"><a [routerLink]="['contact']">Contact</a></li>
    </ul>
  </div>
</nav>

<ul class="sidenav" id="mobile-demo">
  <li class="hover-nav-item"><a [routerLink]="['/']">Accueil</a></li>
  <li class="hover-nav-item"><a [routerLink]="['atelier']">Atelier</a></li>
  <li class="hover-nav-item"><a [routerLink]="['prestations']">Prestations</a></li>
  <li class="hover-nav-item"><a [routerLink]="['galerie']">Galerie</a></li>
  <li class="hover-nav-item"><a [routerLink]="['contact']">Contact</a></li>
</ul>
<router-outlet></router-outlet>

<footer class="page-footer">
  <div class="container">
    <div class="row footer-tel">
      <a [routerLink]="['/']"><img src="../assets/img/LogoSTC.png" width="75px" class="responsive-img logo-tel"/><br></a>
      <div class="col l6 s12 hide-on-small-only">
        666 Chemin de Calameau, 13140 Miramas<br>
        06.60.90.98.71<br>
        <a href="mailto:stc.industrie@gmail.com">stc.industrie@gmail.com</a><br>
      </div>
      <div class="col l4 offset-l2 s12 link-tel">
          <a class="fab fa-facebook-f grey-text text-lighten-3" href="https://www.facebook.com/groups/1108666616136961"> S.T.C. Industrie</a><br>
          <a class="fas fa-mobile grey-text text-lighten-3" [routerLink]="['/contact']"> Nous contacter</a>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container">
    STC Industrie © 2021. Tous droits réservés. 
    </div>
  </div>
</footer>
    