<div class="bg-image row valign-wrapper">
    <div class="container s12 m12">
        <div class="mobile card col s12 m12 s6" style="text-align: center;">
            <h3>NOTRE ATELIER<img src="../../assets/img/soudure.jpg" width="60px" style="float:right;"></h3>
            <hr>
            <div class="card-content">
                <div class="row">
                    <div class="col s12 m12">
                          <div class="card-panel text-center">
                            <span class="blue-text text-darken-2">Notre atelier situé sur la commune de Miramas dans une zone industriel possède un accès poids lourds et d'une surface de travail de 200 m². 
                                Vous trouverez ci-dessous la présentation des postes de travails mis à disposition pour réaliser vos projets.
                            </span>
                          </div>                        
                    </div>
                </div>
                <div class="row">
                    <div class="col s12 m12 text-center"> 
                        <img src="../../assets/img/stc/Atelier3.jpg" width="1500px" class="responsive-img">
                    </div>
                </div>
                <hr>
                <div class="card align-text row">
                    <div class="pos-image1 col s12 m6">
                        <img class="materialboxed responsive-img" src="../../assets/img/stc/Atelier4.jpg" width="500px">
                    </div>
                    <div class="col s12 m6" style="text-align: center;">
                        <h5>POSTE DE TRAVAIL</h5>
                        <br>
                        <p>Notre espace de production est équipé de trois postes :<br></p>
                            <ul>
                               <li>
                                 - Un poste TIG Pulsé - AC/DC
                               </li> 
                               <li>
                                 - Un poste MIG / MAG Pulsé
                               </li>
                               <li>
                                 - Un poste ARC
                              </li>
                            </ul>
                    </div>
                </div>
                <div class="card align-text row">
                    <div class="col s12 m6 text-center">
                        <h5>ROULEUSE</h5>
                        <br>
                        <p>Pour la réalisation de virole ou cône en acier, aluminium, inox jusqu'à <br>6 mm d'épaisseur.</p>
                    </div>
                    <div class="pos-image2 col s12 m6">
                        <img class="materialboxed responsive-img" src="../../assets/img/stc/Atelier2.jpg" width="500px">
                    </div>
                </div>
                <div class="card align-text row">
                    <div class="pos-image3 col s12 m6">
                        <img class="materialboxed responsive-img" src="../../assets/img/stc/Atelier1.jpg" width="300px">
                    </div>
                    <div class="col s12 m6 text-center">
                        <h5>PERCEUSE À COLONNE</h5>
                        <ul>
                            <li>- Capacité de percage acier : 32 mm</li>
                            <li>- Capacité de taraudage : M24</li>
                        </ul>
                    </div>
                </div>
                <div class="card align-text row">
                    <div class="col s12 m6 text-center">
                        <h5>SCIE À RUBAN</h5>
                        <br>
                        <p>Pour la réalisation de coupe sur tout type de profilé</p>
                    </div>
                    <div class="pos-image2 col s12 m6">
                        <img class="materialboxed responsive-img" src="../../assets/img/stc/Atelier5.jpg" width="500px">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>