import { Component, OnInit } from '@angular/core';

declare function sliderGalerie(): any;

@Component({
  selector: 'app-galerie',
  templateUrl: './galerie.component.html',
  styleUrls: ['./galerie.component.scss']
})
export class GalerieComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sliderGalerie();
  }

}
